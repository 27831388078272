<script setup lang="ts">
import ComboBoxV2 from './ComboBoxV2.vue'
import { uniqueId } from 'lodash-es'
import { useComboBoxStore, ComboBoxStore } from './store'
import { Loader } from '@googlemaps/js-api-loader'
import { GooglePlacesSource } from './sources'

const props = defineProps<{
  name: string
  locations: string[]
}>()

const store: ComboBoxStore = useComboBoxStore(uniqueId('locationStore'))
const { source, selections } = storeToRefs(store)

void new Loader({ apiKey: 'AIzaSyCHhiz9OAFI1NPcAHVnmp4z9ON2bA4BcHU', libraries: ['places'] })
  .load()
  .then(google => {
    source.value = new GooglePlacesSource(google)
  })

selections.value = props.locations.map(location => {
  return { id: location, value: location, text1: location }
})
</script>

<template>
  <ComboBoxV2
    :name="name"
    :store="store"
    :readonly="true"
    placeholder="Search Locations"
  />
</template>
