import { Source, Response } from '.'
import { Result } from '../results'

interface Prediction {
  description: string
  place_id: string
}

export default class implements Source {
  private readonly service: any

  constructor (google: any) {
    this.service = new google.maps.places.AutocompleteService()
  }

  async fetch (query: string): Promise<Response> {
    if (query.length === 0) {
      return await Promise.resolve({ data: { results: [] } })
    }

    return this.service.getPlacePredictions({ input: query, types: ['geocode'] })
      .then(async (response: Record<string, Prediction[]>) => {
        const predictions = response.predictions

        const results: Result[] = predictions.map((prediction: Prediction) => ({
          id: prediction.place_id,
          value: this.serializePlace(prediction),
          text1: prediction.description
        }))

        return await Promise.resolve({ data: { results } })
      })
  }

  private serializePlace (prediction: Prediction): string {
    return [prediction.place_id, prediction.description].join('::')
  }
}
