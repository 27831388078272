import { Controller } from '@hotwired/stimulus'

export default class extends Controller<HTMLElement> {
  static targets = ['tab', 'content']

  declare readonly tabTargets: HTMLLinkElement[]
  declare readonly contentTargets: HTMLElement[]

  connect (): void {
    this.showTab()
    addEventListener('turbo:morph', () => { this.showTab() })
  }

  change (e: PointerEvent): void {
    this.index = this.tabTargets.indexOf(e.target as HTMLLinkElement)
  }

  showTab (): void {
    this.tabTargets.forEach((tab, index) => {
      tab.classList.toggle('active', index === this.index)
      this.contentTargets[index].classList.toggle('hidden', index !== this.index)
    })
  }

  get index (): number {
    if (this.data.has('index')) {
      return parseInt(this.data.get('index') as string)
    } else {
      return 0
    }
  }

  set index (value: number) {
    this.data.set('index', value.toString())
    this.showTab()
  }
}
